import './Tracker.css';
import {useEffect, useState} from "react";

function Tracker(props) {
    const [state, setState] = useState(() => {
        return JSON.parse(window.localStorage.getItem(props.localStorageObject)) || {
            'wins': 0,
            'losses': 0,
            'heads': 0,
            'tails': 0
        };
    });

    const updateState = (updated) => setState({...state, ...updated});
    useEffect(() => window.localStorage.setItem(props.localStorageObject, JSON.stringify(state)), [state]);

    const winRatio = (state.wins / (state.wins + state.losses) * 100).toFixed(1);
    const ctWinRatio = (state.heads / (state.heads + state.tails) * 100).toFixed(1);

    return (
            <div className={'tracker-card'}>
                <span className={'heading'}>{props.header}</span>
                <span className={'card-text'}>{state.wins} WINS</span>
                <span className={'card-text'}>{state.losses} LOSSES</span>
                <span className={'card-text win-ratio'}>{parseInt(winRatio) ? winRatio : 0}% Win Ratio</span>
                <div className={'buttonPanel'}>
                    <button type="button" className={'buttonStyle'} onClick={() => updateState({'wins': state.wins + 1})}>+1 WIN </button>
                    <button type="button" className={'buttonStyle'} onClick={() => updateState({'losses': state.losses + 1})}>+1 LOSS </button>
                </div>

                <span className={'card-text'} style={{marginTop: 1.5 + 'rem'}}>COIN TOSS</span>
                <span className={'card-text'}>{state.heads}-{state.tails}</span>
                <span className={'card-text win-ratio'}>{parseInt(ctWinRatio) ? ctWinRatio : 0}% Heads</span>
                <div className={'buttonPanel'}>
                    <button type="button" className={'buttonStyle'} onClick={() => updateState({'heads': state.heads + 1})}>+1 HEADS</button>
                    <button type="button" className={'buttonStyle'} onClick={() => updateState({'tails': state.tails + 1})}>+1 TAILS</button>
                </div>
            </div>
    );
}

export default Tracker;
