import "./Settings.css";
import Cogwheel from "./Cogwheel.svg"

function Settings() {
    function showModal(name) {
        const modal = document.getElementById(name);
        modal.showModal();
        modal.style.display = "flex";
    }

    function getModalValues(name) {
        const node = document.getElementById(name);
        const inputNodes = node.getElementsByTagName('INPUT');

        return {
            'wins': inputNodes[0].value ? parseInt(inputNodes[0].value) : 0,
            'losses': inputNodes[1].value ? parseInt(inputNodes[1].value) : 0,
            'heads': inputNodes[2].value ? parseInt(inputNodes[2].value) : 0,
            'tails': inputNodes[3].value ? parseInt(inputNodes[3].value) : 0,
        };
    }

    function saveSettingsAndCloseModal(modalName, localStorageItem) {
        const modal = document.getElementById(modalName);
        const modalValues = getModalValues(modalName)

        updateLocalStorageItem(localStorageItem, modalValues);

        modal.close();
        window.location.reload();
    }

    function updateLocalStorageItem(name, values) {
        let obj = {
            'wins': values.wins,
            'losses': values.losses,
            'heads': values.heads,
            'tails': values.tails,
        };

        window.localStorage.setItem(name, JSON.stringify(obj));
    }

    return (
        <div className={"settings"}>
            <img src={Cogwheel}
                 height={"32x"}
                 width={"32px"}
                 alt={"Settings"}>
            </img>
            <div className="dropdown">
                <a onClick={() => showModal("ranked-values-modal")}>set ranked values</a>
                <a onClick={() => showModal("dcup-values-modal")}>set duelist cup values</a>
                <a onClick={() => {window.localStorage.clear(); window.location.reload()}}>clear data</a>
            </div>

            <dialog id={"ranked-values-modal"}>
                Ranked
                <input id="rankedWins" type="number"placeholder={"Wins"}></input>
                <input id="rankedLosses" type="number" placeholder={"Losses"}></input>
                <input id="rankedHeads" type="number" placeholder={"Heads"}></input>
                <input id="rankedTails" type="number" placeholder={"Tails"}></input>

                <button onClick={() => saveSettingsAndCloseModal("ranked-values-modal", "ranked")}>Save</button>
            </dialog>

            <dialog id={"dcup-values-modal"}>
                Duelist Cup
                <input id="dcupWins" type="number"placeholder={"Wins"}></input>
                <input id="dcupLosses" type="number" placeholder={"Losses"}></input>
                <input id="dcupHeads" type="number" placeholder={"Heads"}></input>
                <input id="dcupTails" type="number" placeholder={"Tails"}></input>

                <button onClick={() => saveSettingsAndCloseModal("dcup-values-modal", "duelistCup")}>Save</button>
            </dialog>
        </div>
    );
}

export default Settings;
