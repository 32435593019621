import "./App.css";
import Tracker from "./TrackerComponent/Tracker";
import Settings from "./SettingsComponent/Settings";

function App() {

    return (
        <div className='container'>
            <Settings className="settings"/>
            <div className="tracker-container">
                <Tracker header="RANKED"
                         localStorageObject="ranked"/>
                <Tracker header="DUELIST CUP"
                         localStorageObject="duelistCup"/>
            </div>
        </div>
    );
}

export default App;
